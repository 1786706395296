export enum WallColorKey {
    Anthracite = 'anthracite',
    Black = 'black',
    JuniperGreen = 'juniperGreen'
}

export interface WallColor {
    key: string
    name: string
    exampleHexColor: `#${string}`,
    sceneHexColor: `#${string}`
}

export const wallColors: WallColor[] = [{
    key: WallColorKey.Anthracite,
    name: 'Antraciet',
    exampleHexColor: '#404244',
    sceneHexColor: '#404244'
}, {
    key: WallColorKey.Black,
    name: 'Zwart',
    exampleHexColor: '#0e0e10',
    sceneHexColor: '#333333'
}, {
    key: WallColorKey.JuniperGreen,
    name: 'Juniper Green',
    exampleHexColor: '#364427',
    sceneHexColor: '#364427'
}]
