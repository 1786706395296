import { FC } from 'react'
import theme from './theme'
import { ThemeProvider } from '@mui/material/styles'
import Configurator from './configurator'
import { actualShedTypeKeys, shedTypeKeys } from './master-data/standard-shed-types'
import { Props as ConfiguratorProps } from './configurator'

interface Props {
    rootElement: HTMLElement
}

/**
 * Todo fix get parameters
 */
const App:FC<Props> = (props) => {
    const searchParams = new URLSearchParams(window.location.search)

    let configuratorProps:ConfiguratorProps = {
        thankYouPageUrl: null,
        iframeParentUrl: searchParams.get('iframe-parent-url'),
        selectedShedTypeKey: undefined
    }

    let requestedShedTypeKey: undefined|typeof shedTypeKeys[number]
    if (searchParams.get('shed-type') !== null) {
        requestedShedTypeKey = searchParams.get('shed-type') as typeof shedTypeKeys[number]
    } else if (props.rootElement.getAttribute('data-shed-type') !== null) {
        requestedShedTypeKey = props.rootElement.getAttribute('data-shed-type') as typeof shedTypeKeys[number]
    }
    if (requestedShedTypeKey !== undefined) {
        if (actualShedTypeKeys.includes(requestedShedTypeKey)) {
            configuratorProps.selectedShedTypeKey = requestedShedTypeKey
        } else {
            console.error('Unknown shed type key, available keys are: ', actualShedTypeKeys, 'Falling back to default shed type')
        }
    }

    let thankYouPageUrl: undefined|string
    if (searchParams.get('thank-you-page-url') !== null) {
        thankYouPageUrl = searchParams.get('thank-you-page-url') as string
    } else if (props.rootElement.getAttribute('data-thank-you-page-url') !== null) {
        thankYouPageUrl = props.rootElement.getAttribute('data-thank-you-page-url') as string
    }
    if (thankYouPageUrl !== undefined) {
        if (!thankYouPageUrl.startsWith('https://') && !thankYouPageUrl.startsWith('http://')) {
            console.error('data-thank-you-page-url should always start with "http(s)://", falling back to a thank you dialog overlay')
        } else {
            configuratorProps.thankYouPageUrl = thankYouPageUrl
        }
    }

    return <>
        <ThemeProvider theme={theme}>
            <Configurator {...configuratorProps} />
        </ThemeProvider>
    </>
}

export default App
