import { FC } from 'react'
import { Box, Typography } from '@mui/material'
import { steps, StepKey } from './steps'

interface Props {
    activeStep: StepKey
    setActiveStep: (step: StepKey) => void
}

export const TopNav:FC<Props> = (props) => {
    return <Box
        sx={{
            display: 'flex',
            gap: '20px'
        }}
    >
        {steps.map(step => <Box
            key={step.key}
        >
            <Typography

                variant="h6"
                sx={{
                    textDecoration: props.activeStep === step.key ? 'underline' : '',
                    opacity: props.activeStep === step.key ? 1 : 0.32,
                    transition: 'opacity .2s ease',
                    cursor: 'pointer',
                    '&:hover': {
                        opacity: 1
                    }
                }}
                onClick={() => {
                    props.setActiveStep(step.key)
                }}
            >
                {step.title}
            </Typography>
        </Box>)}
    </Box>
}