import { Configuration } from '../../../master-data/configuration'
import React, { FC, ReactNode, useEffect, useMemo, useRef } from 'react'
import standardShedTypes, { StandardShedType } from '../../../master-data/standard-shed-types'
import {
    Box3,
    BoxGeometry,
    Color,
    DoubleSide,
    MathUtils,
    Mesh,
    MeshBasicMaterial,
    MeshPhongMaterial,
    RepeatWrapping,
    Texture,
    Vector2
} from 'three'
import { useDevToolsContext } from '../../dev-tools/context'
import Wall from './wall'
import Roof from './roof'
import { TextureLoader } from 'three/src/loaders/TextureLoader'
import { useLoader } from '@react-three/fiber'
import RainGutter from './rain-gutter'
import renderSettings from '../settings'
import RainPipes from './rain-pipes'
import ConcreteFloor from './concrete-floor'
import { CollisionMeshUserData } from '../colliding-meshes/mesh-user-data'
import { OBB } from 'three/examples/jsm/math/OBB'
import { InitialAnimationProps } from '../animator'
import { roofColors } from '../../../master-data/colors/roof'
import { wallColors } from '../../../master-data/colors/walls'
import { detailingColors } from '../../../master-data/colors/detailing'
import { concretePlinthMaterials } from '../../../master-data/materials/concrete-plinth'
import { bargeboardColors } from '../../../master-data/colors/bargeboard'
import { StandardOrCustomDimensionsOptionKey } from '../../../master-data/standard-or-custom-dimension-options'
import { ShedSideKey } from '../../../master-data/shed-sides'
import Falk1100Tr from './solid-materials/falk-1100-tr'
import { Rabat500 } from './solid-materials/rabat-500'

function roundTo(num: number, precision: number) {
    const factor = Math.pow(10, precision)
    return Math.round(num * factor) / factor
}

interface Props {
    configuration: Configuration
    setConfiguration: (configuration: Configuration) => void
    showDimensions: boolean
    hideShed: boolean
}

const Shed:FC<Props> = (props) => {
    const { options: devToolsOptions } = useDevToolsContext()
    const standardShedType = useMemo(() => {
        return standardShedTypes.find(type => type.key === props.configuration.standardShedType) as StandardShedType
    }, [props.configuration.standardShedType])
    const configurationRef = useRef(props.configuration)

    const wallNormalMap = useLoader(TextureLoader, `${import.meta.env.VITE_BASE_URL}/static/3d/textures/falk-1160-wb-normal-map.png`)
    wallNormalMap.repeat.set(1, 1)
    wallNormalMap.wrapS = RepeatWrapping
    wallNormalMap.wrapT = RepeatWrapping

    const wallTexture = useLoader(TextureLoader, `${import.meta.env.VITE_BASE_URL}/static/3d/textures/falk-1060-wb.png`)
    wallTexture.repeat.set(1, 1)
    wallTexture.wrapS = RepeatWrapping
    wallTexture.wrapT = RepeatWrapping

    const frontAndBackBricksTexture = useLoader(TextureLoader, `${import.meta.env.VITE_BASE_URL}/static/3d/textures/bricks-2.png`)
    frontAndBackBricksTexture.wrapS = RepeatWrapping
    frontAndBackBricksTexture.wrapT = RepeatWrapping

    const leftAndRightBricksTexture = new Texture()
    leftAndRightBricksTexture.copy(frontAndBackBricksTexture)

    const frontAndBackConcreteTexture = useLoader(TextureLoader, `${import.meta.env.VITE_BASE_URL}/static/3d/textures/concrete.jpg`)
    frontAndBackConcreteTexture.wrapS = RepeatWrapping
    frontAndBackConcreteTexture.wrapT = RepeatWrapping

    const leftAndRightConcreteTexture = new Texture()
    leftAndRightConcreteTexture.copy(frontAndBackConcreteTexture)

    const frontAndBackGravelTexture = useLoader(TextureLoader, `${import.meta.env.VITE_BASE_URL}/static/3d/textures/perlin-black-and-white.png`)
    frontAndBackGravelTexture.wrapS = RepeatWrapping
    frontAndBackGravelTexture.wrapT = RepeatWrapping

    const leftAndRightGravelTexture = new Texture()
    leftAndRightGravelTexture.copy(frontAndBackGravelTexture)

    const debugTexture = useLoader(TextureLoader, `${import.meta.env.VITE_BASE_URL}/static/3d/textures/texture-debug.jpg`)
    debugTexture.repeat.set(1, 0.2)
    debugTexture.wrapS = RepeatWrapping
    debugTexture.wrapT = RepeatWrapping

    const rainGutterInitialAnimationProps: InitialAnimationProps = {
        from: {
            position: {
                y: -1
            }
        },
        to: {
            position: {
                y: 0
            }
        },
        delay: 1700,
        duration: 1000
    }

    const rainPipesInitialAnimationProps: InitialAnimationProps = {
        from: {
            position: {
                y: -4
            }
        },
        to: {
            position: {
                y: 0
            }
        },
        delay: 1700,
        duration: 1000
    }

    const fixedCollisionMeshes:ReactNode[] = useMemo(() => {
        const meshes:ReactNode[] = []
        standardShedType.fixedCollisionMeshes.forEach((fixedCollisionMeshProps, index) => {
            const mesh = new Mesh(
                new BoxGeometry(fixedCollisionMeshProps.dimensions.widthMeter, fixedCollisionMeshProps.dimensions.heightMeter, fixedCollisionMeshProps.dimensions.depthMeter),
                new MeshBasicMaterial({
                    color: '#f000ff',
                    wireframe: true
                })
            )
            mesh.geometry.computeBoundingBox()
            const userData: CollisionMeshUserData = {
                obb: new OBB().fromBox3(
                    mesh.geometry.boundingBox as Box3
                ),
                isCollisionMesh: true
            }
            mesh.userData = userData
            mesh.visible = devToolsOptions.showCollisionMeshes
            mesh.position.set(fixedCollisionMeshProps.position.x, fixedCollisionMeshProps.position.y, fixedCollisionMeshProps.position.z)
            meshes.push(<primitive object={mesh} key={index}/>)
        })
        return meshes
    }, [devToolsOptions.showCollisionMeshes, props.configuration.standardShedType])

    useEffect(() => {
        configurationRef.current = props.configuration
    }, [props.configuration])

    const materials = useMemo(() => {
        const roofColor = roofColors.find(color => color.key === props.configuration.roof.color)
        const wallColor = wallColors.find(color => color.key === props.configuration.walls.color)
        const detailingColor = detailingColors.find(color => color.key === props.configuration.detailing.color)
        const concretePlinthMaterial = concretePlinthMaterials.find(material => material.key === props.configuration.concretePlinth.material)
        const bargeboardColor = bargeboardColors.find(color => color.key === props.configuration.bargeboard.color)

        const concretePlinthLeftAndRightMaterial = new MeshPhongMaterial({ wireframe: devToolsOptions.showWireframes })
        const concretePlinthFrontAndBackMaterial = new MeshPhongMaterial({ wireframe: devToolsOptions.showWireframes })

        if (concretePlinthMaterial !== undefined) {
            if (concretePlinthMaterial.key === 'redStoneMotif') {
                frontAndBackBricksTexture.repeat.set(standardShedType.widthMeter * 1.3, 0.7)
                leftAndRightBricksTexture.repeat.set(standardShedType.lengthMeter * 1.3, 0.7)

                concretePlinthLeftAndRightMaterial.map = leftAndRightBricksTexture
                concretePlinthFrontAndBackMaterial.map = frontAndBackBricksTexture
            } else if (concretePlinthMaterial.key === 'concreteGrey') {
                concretePlinthLeftAndRightMaterial.color = new Color('#aaaaaa')
                concretePlinthLeftAndRightMaterial.color = new Color('#aaaaaa')

                frontAndBackConcreteTexture.repeat.set(standardShedType.widthMeter, 0.7)
                leftAndRightConcreteTexture.repeat.set(standardShedType.lengthMeter, 0.7)

                concretePlinthLeftAndRightMaterial.map = leftAndRightConcreteTexture
                concretePlinthFrontAndBackMaterial.map = frontAndBackConcreteTexture
            } else if (concretePlinthMaterial.key === 'anthraciteGravel') {
                concretePlinthLeftAndRightMaterial.color = new Color('#aaaaaa')
                concretePlinthLeftAndRightMaterial.color = new Color('#aaaaaa')

                frontAndBackGravelTexture.repeat.set(standardShedType.widthMeter * 4, 4)
                leftAndRightGravelTexture.repeat.set(standardShedType.lengthMeter * 4, 4)

                concretePlinthLeftAndRightMaterial.map = leftAndRightGravelTexture
                concretePlinthFrontAndBackMaterial.map = frontAndBackGravelTexture
            }
        }

        return {
            wall: new MeshPhongMaterial({
                color: wallColor?.sceneHexColor ?? '#ff0000',
                wireframe: devToolsOptions.showWireframes,
                shininess: 200,
                normalMap: wallNormalMap,
                normalScale: new Vector2(2, 1),
                map: wallTexture
            }),
            concreteFloor: new MeshPhongMaterial({
                color: '#666666',
                wireframe: devToolsOptions.showWireframes,
                side: DoubleSide
            }),
            concretePlinthLeftAndRight: concretePlinthLeftAndRightMaterial,
            concretePlinthFrontAndBack: concretePlinthFrontAndBackMaterial,
            concretePlinthCoverBendedMetal: new MeshPhongMaterial({
                color: wallColor?.sceneHexColor ?? '#ff0000',
                wireframe: devToolsOptions.showWireframes,
                shininess: 200
            }),
            wallCornerBendedMetal: new MeshPhongMaterial({
                color: wallColor?.sceneHexColor ?? '#ff0000',
                wireframe: devToolsOptions.showWireframes,
                shininess: 200
            }),
            windFeatherBendedMetal: new MeshPhongMaterial({
                color: bargeboardColor?.sceneHexColor ?? '#ff0000',
                wireframe: devToolsOptions.showWireframes,
                shininess: 200,
                side: DoubleSide
            }),
            roofRidgeCoverBendedMetal: new MeshPhongMaterial({
                color: roofColor?.sceneHexColor ?? '#ff0000',
                wireframe: devToolsOptions.showWireframes,
                shininess: 200,
                side: DoubleSide
            }),
            roof: new MeshPhongMaterial({
                color: roofColor?.sceneHexColor ?? '#ff0000',
                wireframe: devToolsOptions.showWireframes,
                shininess: 300
            }),
            rainGutter: new MeshPhongMaterial({
                color: '#222222',
                wireframe: devToolsOptions.showWireframes,
                shininess: 200,
            }),
            rainPipes: new MeshPhongMaterial({
                color: '#222222',
                wireframe: devToolsOptions.showWireframes,
                shininess: 200,
            }),
            detailing: new MeshPhongMaterial({
                color: detailingColor?.sceneHexColor ?? '#ff0000',
                wireframe: devToolsOptions.showWireframes,
                shininess: 200
            })
        }
    }, [devToolsOptions.showWireframes,
        wallNormalMap,
        wallTexture,
        props.configuration.roof.color,
        props.configuration.walls.color,
        props.configuration.detailing.color,
        props.configuration.concretePlinth.material,
        props.configuration.bargeboard.color,
        props.configuration.standardShedType
    ])

    const roofCornerDegrees = useMemo(() => {
        return 90 - (MathUtils.radToDeg(Math.atan((standardShedType.widthMeter / 2) / (standardShedType.ridgeHeightMeter - standardShedType.eaveHeightMeter))))
    }, [standardShedType])

    const sharedWallProps = useMemo(() => {
        return {
            wallMaterial: materials.wall,
            concretePlinthFrontAndBackMaterial: materials.concretePlinthFrontAndBack,
            concretePlinthLeftAndRightMaterial: materials.concretePlinthLeftAndRight,
            concretePlinthCoverBendedMetalMaterial: materials.concretePlinthCoverBendedMetal,
            wallCornerBendedMetalMaterial: materials.wallCornerBendedMetal,
            setConfiguration: props.setConfiguration,
            configuration: props.configuration,
            roofCornerDegrees,
            showDimensions: props.showDimensions,
            detailingMaterial: materials.detailing
        }
    }, [props.configuration, devToolsOptions.showWireframes, props.showDimensions])

    const shedDimensions = {
        widthMeter: 0,
        lengthMeter: 0,
        eaveHeightMeter: 0,
        ridgeHeightMeter: 0,
        roofCornerDegrees: 0
    }

    if (props.configuration.standardOrCustomDimensions === StandardOrCustomDimensionsOptionKey.Custom) {
        shedDimensions.widthMeter = props.configuration.customDimensions.widthMeter
        shedDimensions.lengthMeter = props.configuration.customDimensions.lengthMeter
        shedDimensions.eaveHeightMeter = props.configuration.customDimensions.eaveHeightMeter
        const a = shedDimensions.widthMeter / 2
        shedDimensions.ridgeHeightMeter = roundTo((Math.tan(MathUtils.degToRad(props.configuration.customDimensions.roofPitchDegrees)) * a) + shedDimensions.eaveHeightMeter, 2)
    } else {
        shedDimensions.widthMeter = standardShedType.widthMeter
        shedDimensions.lengthMeter = standardShedType.lengthMeter
        shedDimensions.eaveHeightMeter = standardShedType.eaveHeightMeter
        shedDimensions.ridgeHeightMeter = standardShedType.ridgeHeightMeter
    }

    return <group name="Shed"
                  position={[-(shedDimensions.widthMeter / 2), props.hideShed ? 100 : 0, shedDimensions.lengthMeter / 2]}
                  userData={{ isShedRootGroup: true }}
    >
        {fixedCollisionMeshes}
        <group
            name="Concrete floor"
            position={[(shedDimensions.widthMeter / 2), -0.001, -(shedDimensions.lengthMeter / 2)]}
        >
            <ConcreteFloor
                shedWidthMeter={shedDimensions.widthMeter}
                shedLengthMeter={shedDimensions.lengthMeter}
                material={materials.concreteFloor}
            />
        </group>
        <group name={'Front walll'}>
            <Wall
                {...sharedWallProps}
                side={ShedSideKey.Front}
                widthMeter={shedDimensions.widthMeter}
                ridgeHeightMeter={shedDimensions.ridgeHeightMeter}
                eaveHeightMeter={shedDimensions.eaveHeightMeter}
                features={props.configuration.features.filter(feature => feature.side === 'front')}
            />
        </group>
        <group
            name={'Right wall'}
            rotation={[0, Math.PI / 2, 0]}
            position={[shedDimensions.widthMeter, 0, 0]}
        >
            <Wall
                {...sharedWallProps}
                side={ShedSideKey.Right}
                widthMeter={shedDimensions.lengthMeter}
                ridgeHeightMeter={shedDimensions.ridgeHeightMeter}
                eaveHeightMeter={shedDimensions.eaveHeightMeter}
                features={props.configuration.features.filter(feature => feature.side === 'right')}
            />
        </group>
        <group
            name={'Right wall rain gutter'}
            position={[
                shedDimensions.widthMeter - (renderSettings.concretePlinth.thicknessMeter - renderSettings.wall.thicknessMeter - (renderSettings.rainGutter.distanceFromWallMm / 1000)),
                shedDimensions.eaveHeightMeter,
                -(shedDimensions.lengthMeter / 2)
            ]}
            scale={[-1, 1, 1]}
        >
            <group userData={{initialAnimation: rainGutterInitialAnimationProps}}>
                <RainGutter
                    lengthMeter={shedDimensions.lengthMeter + (renderSettings.roof.frontAndBackOverhangMeter * 2) + (renderSettings.rainGutter.extraLengthMm / 1000)}
                    material={materials.rainGutter}/>
            </group>
        </group>
        <group
            name={'Right wall rain pipes'}
            position={[shedDimensions.widthMeter + (renderSettings.rainPipes.distanceFromConcretePlinthMm / 1000),
                shedDimensions.eaveHeightMeter,
                -(shedDimensions.lengthMeter / 2)]}
            scale={[-1, 1, 1]}
        >
            <group userData={{initialAnimation: rainPipesInitialAnimationProps}}>
                <RainPipes shedLengthMeter={shedDimensions.lengthMeter} eaveHeightMeter={shedDimensions.eaveHeightMeter}
                           material={materials.rainPipes}/>
            </group>
        </group>

        <group
            name={'Left wall'}
            rotation={[0, -Math.PI / 2, 0]}
            position={[0, 0, -shedDimensions.lengthMeter]}
        >
            <Wall
                {...sharedWallProps}
                side={ShedSideKey.Left}
                widthMeter={shedDimensions.lengthMeter}
                ridgeHeightMeter={shedDimensions.ridgeHeightMeter}
                eaveHeightMeter={shedDimensions.eaveHeightMeter}
                features={props.configuration.features.filter(feature => feature.side === 'left')}
            />
        </group>
        <group
            name={'Left wall rain gutter'}
            position={[renderSettings.concretePlinth.thicknessMeter - renderSettings.wall.thicknessMeter - (renderSettings.rainGutter.distanceFromWallMm / 1000),
                shedDimensions.eaveHeightMeter,
                -(shedDimensions.lengthMeter / 2)
            ]}
        >
            <group userData={{initialAnimation: rainGutterInitialAnimationProps}}>
                <RainGutter
                    lengthMeter={shedDimensions.lengthMeter + (renderSettings.roof.frontAndBackOverhangMeter * 2) + (renderSettings.rainGutter.extraLengthMm / 1000)}
                    material={materials.rainGutter}/>
            </group>
        </group>
        <group
            name={'Left wall rain pipes'}
            position={[-(renderSettings.rainPipes.distanceFromConcretePlinthMm / 1000),
                shedDimensions.eaveHeightMeter,
                -(shedDimensions.lengthMeter / 2)
            ]}
        >
            <group userData={{initialAnimation: rainPipesInitialAnimationProps}}>
                <RainPipes shedLengthMeter={shedDimensions.lengthMeter} eaveHeightMeter={shedDimensions.eaveHeightMeter}
                           material={materials.rainPipes}/>
            </group>
        </group>

        <group
            name={'Back wall'}
            rotation={[0, Math.PI, 0]}
            position={[shedDimensions.widthMeter, 0, -shedDimensions.lengthMeter]}
        >
            <Wall
                {...sharedWallProps}
                side={ShedSideKey.Back}
                widthMeter={shedDimensions.widthMeter}
                ridgeHeightMeter={shedDimensions.ridgeHeightMeter}
                eaveHeightMeter={shedDimensions.eaveHeightMeter}
                features={props.configuration.features.filter(feature => feature.side === 'back')}
            />
        </group>

        <group name={'Roof'}>
            <Roof
                material={materials.roof}
                shedWidthMeter={shedDimensions.widthMeter}
                shedLengthMeter={shedDimensions.lengthMeter}
                shedEaveHeightMeter={shedDimensions.eaveHeightMeter}
                shedRidgeHeightMeter={shedDimensions.ridgeHeightMeter}
                materialTypeKey={props.configuration.roof.material}
                windFeatherMaterial={materials.windFeatherBendedMetal}
                roofRidgeCoverBendedMetalMaterial={materials.roofRidgeCoverBendedMetal}
            />
        </group>
    </group>
}

export default Shed
