import { FC } from 'react'
import { Configuration, SetConfiguration } from '../../../../master-data/configuration'
import { Box, FormControl, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material'
import { StandardDimensions } from './dimensions/standard'
import { CustomDimensions } from './dimensions/custom'
import standardShedTypes from '../../../../master-data/standard-shed-types'
import { MathUtils } from 'three'
import {
    standardOrCustomDimensionOptions,
    StandardOrCustomDimensionsOptionKey
} from '../../../../master-data/standard-or-custom-dimension-options'

interface Props {
    configuration: Configuration
    setConfiguration: SetConfiguration
}

export const Dimensions:FC<Props> = (props) => {
    return <>
        <Typography
            variant="h5"
            gutterBottom
        >
            Afmetingen
        </Typography>
        <Typography>
            De afmetingen van uw DUBLO, een standaard afmeting is in de meeste gevallen goedkoper.
        </Typography>

        <Box
            sx={{
                marginTop: 1
            }}
        >
            <FormControl>
                <RadioGroup
                    name="standardOrCustomDimensions"
                    row
                    value={props.configuration.standardOrCustomDimensions}
                    onChange={(event, value: string) => {
                        const newConfiguration = {
                            ...props.configuration,
                            standardOrCustomDimensions: value as StandardOrCustomDimensionsOptionKey
                        }
                        if (value === StandardOrCustomDimensionsOptionKey.Standard) {
                            //newConfiguration.features = []
                            // todo?
                        }
                        if (value === StandardOrCustomDimensionsOptionKey.Custom) {
                            const standardShedType = standardShedTypes.find(a => a.key === props.configuration.standardShedType)
                            if (standardShedType !== undefined) {
                                const roofPitchDegrees = 90 - (MathUtils.radToDeg(Math.atan((standardShedType.widthMeter / 2) / (standardShedType.ridgeHeightMeter - standardShedType.eaveHeightMeter))))
                                newConfiguration.customDimensions = {
                                    widthMeter: standardShedType.widthMeter,
                                    lengthMeter: standardShedType.lengthMeter,
                                    eaveHeightMeter: standardShedType.eaveHeightMeter,
                                    roofPitchDegrees: Math.round(roofPitchDegrees)
                                }
                            }
                        }
                        props.setConfiguration(newConfiguration)
                    }}
                >
                    {standardOrCustomDimensionOptions.map(option => {
                        return <FormControlLabel
                            key={option.key}
                            value={option.key}
                            control={<Radio />}
                            label={option.title}
                        />
                    })}
                </RadioGroup>
            </FormControl>
        </Box>

        <Box
            sx={{
                marginTop: 3
            }}
        >
            {props.configuration.standardOrCustomDimensions === StandardOrCustomDimensionsOptionKey.Standard && <>
                <StandardDimensions
                    configuration={props.configuration}
                    setConfiguration={props.setConfiguration}
                />
            </>}
            {props.configuration.standardOrCustomDimensions === StandardOrCustomDimensionsOptionKey.Custom && <>
                <CustomDimensions
                    configuration={props.configuration}
                    setConfiguration={props.setConfiguration}
                />
            </>}
        </Box>
    </>
}
