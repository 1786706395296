import { FC, ReactNode } from 'react'

interface Props {
    children?: ReactNode,
    pivotPoint: [number, number, number],
    rotation: [number, number, number],
    showPivotPoint?: boolean,
    enabled?: boolean
}

const defaultProps = {
    pivotPoint: [0, 0, 0],
    rotation: [0, 0, 0],
    showPivotPoint: false,
    enabled: true
}

const ObjectRotator:FC<Props> = (unDefaultizedProps) => {
    const props = {
        ...defaultProps,
        ...unDefaultizedProps
    }

    return <>
        {props.enabled &&
            <>
                {props.showPivotPoint &&
                    <axesHelper position={props.pivotPoint} />
                }
                <group
                    position={props.pivotPoint}
                    rotation={props.rotation}
                >
                    <group position={[-props.pivotPoint[0], -props.pivotPoint[1], -props.pivotPoint[2]]}>
                        {props.children}
                    </group>
                </group>
            </>
        }
        {!props.enabled && props.children}
    </>
}

export default ObjectRotator
