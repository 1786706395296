import { FC } from 'react'
import { Configuration, SetConfiguration } from '../../../../master-data/configuration'
import { Box, Typography } from '@mui/material'
import { DubloFunction } from './dublo-function'
import { Dimensions } from './dimensions'

interface Props {
    configuration: Configuration
    setConfiguration: SetConfiguration
}

export const ModelStep:FC<Props> = (props) => {
    return <>
        <Typography
            variant="h4"
            gutterBottom
        >
            Configureer jouw unieke DUBLO
        </Typography>
        <Typography>
            Van oldtimerstalling tot (mantelzorg)woning en van werkplaats tot bedrijfskantine. DUBLO is het ultieme antwoord op jouw vraag naar een veelzijdig en duurzaam bijgebouw.
        </Typography>
        <Typography
            variant="h5"
            sx={{
                marginTop: 4
            }}
        >
            Kies eerst de functie van jouw DUBLO
        </Typography>
        <Box
            sx={{
                marginTop: 2
            }}
        >
            <DubloFunction
                configuration={props.configuration}
                setConfiguration={props.setConfiguration}
            />
        </Box>
        <Box
            sx={{
                marginTop: 6
            }}
        >
            <Dimensions
                configuration={props.configuration}
                setConfiguration={props.setConfiguration}
            />
        </Box>
    </>
}


