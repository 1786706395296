import { FC, useMemo, useRef } from 'react'
import { useDevToolsContext } from '../../dev-tools/context'
import { Object3D } from 'three'
import { degToRad } from 'three/src/math/MathUtils'

const xDistance = 10
const yDistance = 10
const zDistance = 10
const spotlightPositions:[number, number, number][] = [
    [10, 10, 10],
    [10, 10, -10],
    [-10, 10, 10],
    [-10, 10, -10]
]

const Lights:FC = () => {
    const { options } = useDevToolsContext()

    const directionalLightRef = useRef(null) as any
    const spotlight1Ref = useRef(null) as any
    const spotlight2Ref = useRef(null) as any

    const spotlight4Ref = useRef()

    const spotlightTargets = useMemo(() => {
        return {
            spotlight1: new Object3D(),
            spotlight2: new Object3D(),
            spotlight3: new Object3D()
        }
    }, [])

    return <>
        {/*<primitive object={spotlightTargets.spotlight2} position={[5, 0, 0]}></primitive>*/}
        {/*<primitive object={spotlightTargets.spotlight3} position={[5, 0, 0]}></primitive>*/}

        <ambientLight intensity={2.6} />

        <directionalLight
            name="Sun"
            ref={directionalLightRef}
            position={[-5, 10, 10]}
            intensity={0.3}
            castShadow
            shadow-mapSize-height={1024 * 4}
            shadow-mapSize-width={1024 * 4}
            shadow-camera-left={-10}
            shadow-camera-right={10}
            shadow-camera-top={10}
            shadow-camera-bottom={-10}
        />
        {/*{options.showLightHelpers && directionalLightRef.current !== undefined &&*/}
        {/*    <directionalLightHelper args={[directionalLightRef.current, 3, '#ffcc00']} />*/}
        {/*}*/}

        {/*{spotlightPositions.map((position) => {*/}
        {/*    return <spotLight*/}
        {/*        args={['#ffffff']}*/}
        {/*        target={spotlightTargets.spotlight1}*/}
        {/*        ref={spotlight1Ref}*/}
        {/*        position={position}*/}
        {/*        intensity={1}*/}
        {/*        distance={20}*/}
        {/*        angle={degToRad(45) / 2}*/}
        {/*        decay={0.5}*/}
        {/*        penumbra={1}*/}
        {/*    />*/}
        {/*})}*/}

        <spotLight
            args={['#ffffff']}
            target={spotlightTargets.spotlight1}
            ref={spotlight1Ref}
            position={[20, 10, -30]}
            intensity={0.4}
            distance={40}
            angle={degToRad(80) / 2}
            decay={1}
            penumbra={0.5}
            userData={{
                isSun: true
            }}
        />
        <primitive object={spotlightTargets.spotlight1} position={[0, 0, 0]}></primitive>
        {options.showLightHelpers && spotlight1Ref.current !== undefined &&
            <spotLightHelper args={[spotlight1Ref.current, '#ffcc00']} />
        }

        {/*<spotLight*/}
        {/*    args={['#ffffff']}*/}
        {/*    target={spotlightTargets.spotlight2}*/}
        {/*    ref={spotlight2Ref}*/}
        {/*    position={[5, 15, -30]}*/}
        {/*    intensity={0.2}*/}
        {/*    distance={40}*/}
        {/*    angle={degToRad(30) / 2}*/}
        {/*    decay={0.5}*/}
        {/*    penumbra={1}*/}
        {/*/>*/}
        {/*<primitive object={spotlightTargets.spotlight2} position={[20, 1, 0]}></primitive>*/}
        {/*{options.showLightHelpers && spotlight2Ref.current !== undefined &&*/}
        {/*    <spotLightHelper args={[spotlight2Ref.current, '#ffcc00']} />*/}
        {/*}*/}


        {/*{options.showLightHelpers && directionalLightRef.current !== undefined &&*/}
        {/*    <spotLightHelper args={[directionalLightRef.current, '#ffcc00']} />*/}
        {/*}*/}


        {/*<directionalLight ref={directionalLight3Ref} position={[-5, 10, -10]} intensity={0.2} />*/}
        {/*{options.showLightHelpers && directionalLight3Ref.current !== undefined &&*/}
        {/*    <directionalLightHelper args={[directionalLight3Ref.current, 3, '#ffcc00']} />*/}
        {/*}*/}
    </>
}

export default Lights
