import { Configuration, SetConfiguration } from '../../../../master-data/configuration'
import { FC, useState } from 'react'
import { Box, Button, FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material'
import { WallFeatureKey, wallFeatures } from '../../../../master-data/wall-features'
import AddIcon from '@mui/icons-material/Add'
import { generateInteractableObjectUuid } from '../../../interactable-object/context'
import { ShedSideKey } from '../../../../master-data/shed-sides'

interface Props {
    configuration: Configuration
    setConfiguration: SetConfiguration
    cameraIsFacingSide: ShedSideKey
}

export const DoorsAndWindows:FC<Props> = (props) => {
    const [wallFeatureKey, setWallFeatureKey] = useState<WallFeatureKey>(WallFeatureKey.Door)

    return <>
        <Box
            sx={{
                display: 'flex',
                gap: 2,
                alignItems: 'stretch'
            }}
        >
            <Box>
                <Button
                    color="primary"
                    size="medium"
                    variant="outlined"
                    sx={{
                        minHeight: '100%'
                    }}
                    onClick={() => {
                        const newConfiguration = {...props.configuration}
                        newConfiguration.features.push({
                            featureKey: wallFeatureKey,
                            distanceLeftMeter: 1,
                            side: props.cameraIsFacingSide,
                            uuid: generateInteractableObjectUuid()
                        })
                        props.setConfiguration(newConfiguration)
                    }}
                >
                    <AddIcon />
                </Button>
            </Box>

            <Box
                sx={{
                    flexGrow: 1
                }}
            >

                <FormControl
                    fullWidth
                >
                    <InputLabel>
                        Toevoegen
                    </InputLabel>
                    <Select
                        value={wallFeatureKey}
                        label="Toevoegen"
                        fullWidth
                        onChange={(event) => {
                            setWallFeatureKey(event.target.value as WallFeatureKey)
                        }}
                    >
                        {wallFeatures.map((wallFeature) => {
                            return <MenuItem
                                value={wallFeature.key}
                                key={wallFeature.key}
                            >
                                {/*<img*/}
                                {/*    src={`${import.meta.env.VITE_DUBLO_APP_FILES_URL}/static/option-images/${wallFeature.optionImageFilename}`}*/}
                                {/*    alt={wallFeature.name}*/}
                                {/*    style={{*/}
                                {/*        width: '40px',*/}
                                {/*        height: '25px',*/}
                                {/*        objectFit: 'contain',*/}
                                {/*        display: 'inline-block',*/}
                                {/*        verticalAlign: 'middle',*/}
                                {/*        transform: 'scale(1.7, 1.7)',*/}
                                {/*        marginRight: '15px'*/}
                                {/*    }}*/}
                                {/*/>*/}
                                {wallFeature.name}
                            </MenuItem>
                        })}
                    </Select>

                </FormControl>
            </Box>

        </Box>
        <Box>
            <FormHelperText>
                Draai je DUBLO eerst naar een zijde waar je een raam of deur wilt toevoegen.<br />
                Sleep een deur of raam om de positie te veranderen.<br />
                Klik op een deur of raam om deze te verwijderen.
            </FormHelperText>
        </Box>
    </>
}