import { FC, useEffect, useMemo, useState, Suspense, useRef } from 'react'
import { Canvas } from '@react-three/fiber'
import { useContextBridge, Stats, Text } from '@react-three/drei'
import Shed from './shed'
import { Configuration } from '../../master-data/configuration'
import Lights from './lights'
import { DevToolsContext, useDevToolsContext } from '../dev-tools/context'
import { InteractableObjectContext, useInteractableObjectContext } from '../interactable-object/context'
import DevToolsThreejsObjects from '../dev-tools/threejs-objects'
import { OrbitControlsContext } from './orbit-controls/context'
import OrbitControls from './orbit-controls'
import { EffectComposer, Outline, SMAA, Selection } from '@react-three/postprocessing'
import CanvasPointerSetter from './canvas-pointer-setter'
import CollidingMeshesDetector from './colliding-meshes/detector'
import CollidingMeshesContextProvider from './colliding-meshes/context'
import FloorPlane from './floor-plane'
import ScreenShotter from './screen-shotter'
import { SelectedObjectMeshTopRight2DPositionContext } from '../selected-object/mesh-top-right-2d-position/context'
import SelectedObject2dPositionSetter from './selected-object-2d-position-setter'
import OnEscKeyDeSelector from './on-esc-key-deselector'
import SunPointer from './sun-pointer/sun-pointer'
import ScreenShotterShedRequest from './screen-shotter-shed-request'
import { XR } from '@react-three/xr'
import ArGroup from '../ar-group'
import DimensionTextToCameraPointer from './dimension-text-to-camera-pointer'
import standardShedTypes, { StandardShedType } from '../../master-data/standard-shed-types'
import ArSessionEndStuffResetter from './ar-session-end-stuff-resetter'
import CameraFacingShedSideUpdater from './camera-facing-shed-side-updater'
import Environment from './environment'
import Animator from './animator'
import ReadyNotifier from './ready-notifier'
import PhotoStudio from './photo-studio'
import { MathUtils, MeshPhongMaterial } from 'three'
import { ShedSideKey } from '../../master-data/shed-sides'
import { StandardOrCustomDimensionsOptionKey } from '../../master-data/standard-or-custom-dimension-options'
import { Rabat500 } from './shed/solid-materials/rabat-500'
import Falk1100Tr from './shed/solid-materials/falk-1100-tr'
// import SkyBox from './sky-box'

interface Props {
    configuration: Configuration
    setConfiguration: (configuration: Configuration) => void
    showDimensions: boolean
    setShowDimensions: (bool: boolean) => void
    makeScreenshot: boolean
    makeScreenshotForShedRequest: boolean
    screenshotMadeCallback: (blob: Blob) => void
    doHitTests: boolean
    arModeEnabled: boolean
    onArSessionStart: () => void
    onArSessionEnd: () => void
    arCurrentMode: 'place-marker'|'viewing'
    arObjectScale: number
    arObjectRotation: number
    cameraFacingSideChangeCallback: (newSide: ShedSideKey) => void
    doInitialAnimation: boolean
    readyCallback: () => void
    hitTestCallback: () => void
}

function usePrevious<T>(value: T): T {
    // The ref object is a generic container whose current property is mutable ...
    // ... and can hold any value, similar to an instance property on a class
    const ref: any = useRef<T>();
    // Store current value in ref
    useEffect(() => {
        ref.current = value;
    }, [value]); // Only re-run if value changes
    // Return previous value (happens before update in useEffect above)
    return ref.current;
}

/**
 * Todo rename to something more generic? It is more then only the renderer
 */
const Renderer:FC<Props> = (props) => {
    const ContextBridge = useContextBridge(DevToolsContext, InteractableObjectContext, OrbitControlsContext, SelectedObjectMeshTopRight2DPositionContext)
    const { options: devToolsOptions } = useDevToolsContext()
    const { selectedObject } = useInteractableObjectContext()
    const standardShedType = useMemo(() => {
        return standardShedTypes.find(type => type.key === props.configuration.standardShedType) as StandardShedType
    }, [props.configuration.standardShedType])
    const [resetArStuff, setResetArStuff] = useState(false)
    const previousConfiguration = usePrevious(props.configuration)
    const [hideShed, setHideShed] = useState(true)

    const cameraFacingSideChangeHandler = (newSide: ShedSideKey) => {
        props.cameraFacingSideChangeCallback(newSide)
    }

    useEffect(() => {
        setResetArStuff(!props.arModeEnabled)
    }, [props.arModeEnabled])

    const renderReadyCallback = () => {
        props.readyCallback()
        window.setTimeout(() => { // dirty fix, prevent the shed to be visible for a very brief moment before the initial animation starts
            setHideShed(false)
        }, 200)
    }

    const shedDimensions = {
        widthMeter: 0,
        lengthMeter: 0
    }

    if (props.configuration.standardOrCustomDimensions === StandardOrCustomDimensionsOptionKey.Custom) {
        shedDimensions.widthMeter = props.configuration.customDimensions.widthMeter
        shedDimensions.lengthMeter = props.configuration.customDimensions.lengthMeter
    } else {
        shedDimensions.widthMeter = standardShedType.widthMeter
        shedDimensions.lengthMeter = standardShedType.lengthMeter
    }

    return <>
        {import.meta.env.DEV && devToolsOptions.showStats &&
            <Stats />
        }
        <Canvas
            gl={{
                preserveDrawingBuffer: true
            }}
            shadows
            frameloop="demand"
            onCreated={(rootState) => {
                rootState.camera.position.x = 12
                rootState.camera.position.y = 5
                rootState.camera.position.z = 13
            }}
        >
            <XR
                referenceSpace="local"
                onSessionStart={props.onArSessionStart}
                onSessionEnd={props.onArSessionEnd}
            >
                <ContextBridge>
                    <CollidingMeshesContextProvider>
                        <Selection enabled={selectedObject !== null}>
                            <ArGroup
                                arModeEnabled={props.arModeEnabled}
                                arCurrentMode={props.arCurrentMode}
                                arObjectScale={props.arObjectScale}
                                arObjectRotation={props.arObjectRotation}
                                shedLengthMeter={standardShedType.lengthMeter}
                                hitTestCallback={props.hitTestCallback}
                            >
                                {import.meta.env.DEV &&
                                    <PhotoStudio
                                        setConfiguration={props.setConfiguration}
                                    />
                                }
                                {/* filthy font preload hack */}
                                <Text
                                    visible={false}
                                    font="/static/fonts/sans-serif.normal.400.woff"
                                >.</Text>
                                <ReadyNotifier readyCallback={renderReadyCallback} />
                                <Animator doInitialAnimation={props.doInitialAnimation} />
                                <CameraFacingShedSideUpdater onFacingSideChange={cameraFacingSideChangeHandler} />
                                <SelectedObject2dPositionSetter />
                                <ArSessionEndStuffResetter
                                    resetStuff={resetArStuff}
                                />
                                {/*<DimensionTextToCameraPointer />*/}
                                <SunPointer />
                                <OnEscKeyDeSelector />
                                <ScreenShotter
                                    makeScreenshot={props.makeScreenshot}
                                    screenshotMadeCallback={props.screenshotMadeCallback}
                                />
                                <ScreenShotterShedRequest
                                    shedTypeKey={props.configuration.standardShedType}
                                    showDimensions={props.showDimensions}
                                    setShowDimensions={props.setShowDimensions}
                                    makeScreenshot={props.makeScreenshotForShedRequest}
                                    screenshotMadeCallback={props.screenshotMadeCallback}
                                />
                                <Lights />
                                <CollidingMeshesDetector configuration={props.configuration} />
                                <FloorPlane
                                    visible={!props.arModeEnabled}
                                    shedLengthMeter={shedDimensions.lengthMeter}
                                    shedWithMeter={shedDimensions.widthMeter}
                                />
                                {/*<SkyBox />*/}
                                <Suspense fallback={previousConfiguration !== undefined &&
                                    <>
                                        <Environment />
                                        <Shed
                                            configuration={previousConfiguration}
                                            setConfiguration={props.setConfiguration}
                                            showDimensions={props.showDimensions}
                                            hideShed={true}
                                        />
                                    </>
                                } >
                                    <Environment />

                                    {/*<Rabat500 widthMeter={1} heightMeter={1} thicknessMeter={0.1} material={new MeshPhongMaterial()} />*/}
                                    {/*<Falk1100Tr widthMeter={1} heightMeter={1} thicknessMeter={0.1} material={new MeshPhongMaterial()} />*/}

                                    <group
                                        // visible={false}
                                    >
                                        <Shed
                                            configuration={props.configuration}
                                            setConfiguration={props.setConfiguration}
                                            showDimensions={props.showDimensions}
                                            hideShed={hideShed}
                                        />
                                    </group>

                                </Suspense>
                                <CanvasPointerSetter />
                                <OrbitControls />
                                <DevToolsThreejsObjects />
                            </ArGroup>
                            {/* effectComposer does not play well with XR */}
                            {/*<EffectComposer autoClear={!props.arModeEnabled} enabled={!props.arModeEnabled}>*/}
                            {/*    <Outline*/}
                            {/*        // width={200}*/}
                            {/*        // height={200}*/}
                            {/*        blur*/}
                            {/*        edgeStrength={4}*/}
                            {/*        pulseSpeed={0}*/}
                            {/*        visibleEdgeColor={0xffffff}*/}
                            {/*        hiddenEdgeColor={0xffffff}*/}
                            {/*    />*/}
                            {/*    <SMAA/>*/}
                            {/*</EffectComposer>*/}
                        </Selection>
                    </CollidingMeshesContextProvider>
                </ContextBridge>
            </XR>
        </Canvas>
    </>
}

export default Renderer
