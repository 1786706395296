export enum WallMaterialKey {
    SandwichPanel = 'sandwichPanel',
    OverlappingCladding = 'overlappingCladding',
    UnIsolated = 'unIsolated'
}

export interface WallMaterial {
    key: WallMaterialKey
    name: string
    optionImageFilePath?: string
}

export const wallMaterials: WallMaterial[] = [{
    key: WallMaterialKey.SandwichPanel,
    name: 'Sandwichpaneel',
}, {
    key: WallMaterialKey.OverlappingCladding,
    name: 'Potdeksel'
}, {
    key: WallMaterialKey.UnIsolated,
    name: 'Ongeïsoleerd'
}]
