/**
 * @deprecated
 */
const wallFeatureKeys = ['window', 'door', 'overheadDoor3x3', 'overheadDoor2.5x2.5', 'doubleDoorWithSidePanels', 'doubleDoorWithoutSidePanels'] as const
export enum WallFeatureKey {
    Window = 'window',
    Door = 'door',
    OverheadDoor3x3 = 'overheadDoor3x3',
    OverheadDoor2dot5x2dot5 = 'overheadDoor2.5x2.5',
    DoubleDoorWithSidePanels = 'doubleDoorWithSidePanels',
    DoubleDoorWithoutSidePanels = 'doubleDoorWithoutSidePanels'
}

interface WallFeatureCollisionMesh {
    widthMeter: number
    heightMeter: number
    depthMeter: number
    offset: {
        x: number
        y: number
        z: number
    }
}

interface WallFeature {
    name: string
    /**
     * Must be unique, for internal use, radio button value for example
     */
    key: WallFeatureKey
    optionImageFilename?: string,
    priceEuro: number
    /**
     * The hole is always a rectangle
     */
    cutHoleInWall: boolean
    /**
     * Todo this is obsolete I think, we can use offsets for this
     */
    distanceFromBottomMeter: number
    holeInWall: {
        widthMeter: number
        heightMeter: number
        offset: {
            x: number
            y: number
        }
    }
    interactionMesh: {
        widthMeter: number
        heightMeter: number
        depthMeter: number
        minDistanceToWallCornerMeter: number
        minDistanceToWindFeatherMeter?: number
        offset: {
            x: number
            y: number
            z: number
        }
    }
    dimensions: {
        widthMeter: number
        heightMeter: number
        depthMeter: number
        // todo not too sure about this, makes more sense to determine this in the feature jsx object instead
        offset: {
            x: number
            y: number
            z: number
        }
    }
    collisionMeshes: WallFeatureCollisionMesh[]
    feature3DObject: {
        offset: {
            x: number
            y: number
            z: number
        }
    }
}

const wallFeatures: WallFeature[] = [{
    name: 'Raam',
    key: WallFeatureKey.Window,
    optionImageFilename: 'window.png',
    priceEuro: 1450,
    cutHoleInWall: true,
    distanceFromBottomMeter: 1.36,
    holeInWall: {
        widthMeter: 2,
        heightMeter: 1,
        offset: {
            x: 0,
            y: 0
        }
    },
    interactionMesh: {
        widthMeter: 2,
        heightMeter: 1,
        depthMeter: 0.2,
        minDistanceToWallCornerMeter: 0.5,
        offset: {
            x: 0,
            y: 0,
            z: 0
        }
    },
    dimensions: {
        widthMeter: 2,
        heightMeter: 1,
        depthMeter: 0,
        offset: {
            x: 0,
            y: 0,
            z: 0
        }
    },
    collisionMeshes: [{
        widthMeter: 2,
        heightMeter: 1,
        depthMeter: 0.3,
        offset: {
            x: 0,
            y: 0,
            z: 0
        }
    }],
    feature3DObject: {
        offset: {
            x: 0,
            y: 0,
            z: 0
        }
    }
}, {
    name: 'Deur',
    key: WallFeatureKey.Door,
    optionImageFilename: 'door.png',
    priceEuro: 2150,
    cutHoleInWall: true,
    distanceFromBottomMeter: 0,
    holeInWall: {
        widthMeter: 1,
        heightMeter: 2.36,
        offset: {
            x: 0,
            y: 0
        }
    },
    interactionMesh: {
        widthMeter: 1,
        heightMeter: 2.37,
        depthMeter: 0.2,
        minDistanceToWallCornerMeter: 0.5,
        offset: {
            x: 0,
            y: 0,
            z: 0
        }
    },
    dimensions: {
        widthMeter: 1,
        heightMeter: 2.36,
        depthMeter: 0,
        offset: {
            x: 0,
            y: 0,
            z: 0
        }
    },
    collisionMeshes: [{
        widthMeter: 1,
        heightMeter: 2.37,
        depthMeter: 0.2,
        offset: {
            x: 0,
            y: 0,
            z: 0
        }
    }, {
        // door stopper
        widthMeter: 0.3,
        heightMeter: 0.1,
        depthMeter: 0.4,
        offset: {
            x: 1.1,
            y: 0.6,
            z: 0.2
        }
    }],
    feature3DObject: {
        offset: {
            x: 0,
            y: 0,
            z: 0
        }
    }
}, {
    name: 'Dubbele deur met zijpanelen',
    key: WallFeatureKey.DoubleDoorWithSidePanels,
    optionImageFilename: 'double-door-with-side-panels.png',
    priceEuro: 4250,
    cutHoleInWall: true,
    distanceFromBottomMeter: 0,
    holeInWall: {
        widthMeter: 3,
        heightMeter: 2.5,
        offset: {
            x: 0,
            y: 0
        }
    },
    interactionMesh: {
        widthMeter: 3,
        heightMeter: 2.5,
        depthMeter: 0.2,
        minDistanceToWallCornerMeter: 0.5,
        offset: {
            x: 0,
            y: 0,
            z: 0
        }
    },
    dimensions: {
        widthMeter: 3,
        heightMeter: 2.5,
        depthMeter: 0,
        offset: {
            x: 0,
            y: 0,
            z: 0
        }
    },
    collisionMeshes: [{
        widthMeter: 3,
        heightMeter: 2.5,
        depthMeter: 0.3,
        offset: {
            x: 0,
            y: 0,
            z: 0.1
        }
    }],
    feature3DObject: {
        offset: {
            x: 0,
            y: 0,
            z: 0
        }
    }
}, {
    name: 'Dubbele deur zonder zijpanelen',
    key: WallFeatureKey.DoubleDoorWithoutSidePanels,
    optionImageFilename: 'double-door-without-side-panels.png',
    priceEuro: 4250,
    cutHoleInWall: true,
    distanceFromBottomMeter: 0,
    holeInWall: {
        widthMeter: 2,
        heightMeter: 2.5,
        offset: {
            x: 0,
            y: 0
        }
    },
    interactionMesh: {
        widthMeter: 2,
        heightMeter: 2.5,
        depthMeter: 0.2,
        minDistanceToWallCornerMeter: 0.5,
        offset: {
            x: 0,
            y: 0,
            z: 0
        }
    },
    dimensions: {
        widthMeter: 2,
        heightMeter: 2.5,
        depthMeter: 0,
        offset: {
            x: 0,
            y: 0,
            z: 0
        }
    },
    collisionMeshes: [{
        widthMeter: 2,
        heightMeter: 2.5,
        depthMeter: 0.3,
        offset: {
            x: 0,
            y: 0,
            z: 0.1
        }
    }],
    feature3DObject: {
        offset: {
            x: 0,
            y: 0,
            z: 0
        }
    }
}, {
    name: 'Overhead deur 2.5m x 2.5m',
    key: WallFeatureKey.OverheadDoor2dot5x2dot5,
    optionImageFilename: 'overhead-door-2.5x2.5.png',
    priceEuro: 4250,
    cutHoleInWall: true,
    distanceFromBottomMeter: 0,
    holeInWall: {
        widthMeter: 2.5,
        heightMeter: 2.51,
        offset: {
            x: 0,
            y: 0
        }
    },
    interactionMesh: {
        widthMeter: 2.668,
        heightMeter: 2.51,
        depthMeter: 0.2,
        minDistanceToWallCornerMeter: 0.283,
        minDistanceToWindFeatherMeter: 0.3,
        offset: {
            x: -0.083,
            y: 0,
            z: 0
        }
    },
    dimensions: {
        widthMeter: 2.5,
        heightMeter: 2.465,
        depthMeter: 0,
        offset: {
            x: 0,
            y: 0,
            z: 0
        }
    },
    collisionMeshes: [{
        widthMeter: 2.668,
        heightMeter: 2.51,
        depthMeter: 0.2,
        offset: {
            x: -0.083,
            y: 0,
            z: 0
        }
    }],
    feature3DObject: {
        offset: {
            x: 0,
            y: 0,
            z: 0
        }
    }
}, {
    name: 'Overhead deur 3m x 3m',
    key: WallFeatureKey.OverheadDoor3x3,
    optionImageFilename: 'overhead-door-3x3.png',
    priceEuro: 4250,
    cutHoleInWall: true,
    distanceFromBottomMeter: 0,
    holeInWall: {
        widthMeter: 3,
        heightMeter: 3.01,
        offset: {
            x: 0,
            y: 0
        }
    },
    interactionMesh: {
        widthMeter: 3.168,
        heightMeter: 3.01,
        depthMeter: 0.2,
        minDistanceToWallCornerMeter: 0.583,
        minDistanceToWindFeatherMeter: 0.55,
        offset: {
            x: -0.083,
            y: 0,
            z: 0
        }
    },
    dimensions: {
        widthMeter: 3,
        heightMeter: 2.965,
        depthMeter: 0,
        offset: {
            x: 0,
            y: 0,
            z: 0
        }
    },
    collisionMeshes: [{
        widthMeter: 3.168,
        heightMeter: 3.01,
        depthMeter: 0.2,
        offset: {
            x: -0.083,
            y: 0,
            z: 0
        }
    }],
    feature3DObject: {
        offset: {
            x: 0,
            y: 0,
            z: 0
        }
    }
}]

export { wallFeatureKeys, wallFeatures }
export { type WallFeature }
