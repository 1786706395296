import { FC } from 'react'
import { Configuration, SetConfiguration } from '../../../../../master-data/configuration'
import { Grid, Slider } from '@mui/material'
import NumberInputWithSteppersSmallVariant from '../../../number-input-with-steppers/small-variant'
import { round } from 'lodash'

interface Props {
    configuration: Configuration
    setConfiguration: SetConfiguration
}

export const CustomDimensions:FC<Props> = (props) => {


    return <>
        <Grid container spacing={4}>
            <Grid item xs={4}>
                <NumberInputWithSteppersSmallVariant
                    value={props.configuration.customDimensions.widthMeter}
                    minValue={3}
                    maxValue={20}
                    stepperButtonsStepAmount={1}
                    decimals={1}
                    inputLabel="Breedte"
                    inputUnit="m"
                    onChangeCallback={(newValue) => {
                        props.setConfiguration({
                            ...props.configuration,
                            customDimensions: {
                                ...props.configuration.customDimensions,
                                widthMeter: newValue
                            }
                        })
                    }}
                />
            </Grid>
            <Grid item xs={4}>
                <NumberInputWithSteppersSmallVariant
                    value={props.configuration.customDimensions.lengthMeter}
                    minValue={6}
                    maxValue={50}
                    stepperButtonsStepAmount={1}
                    decimals={1}
                    inputLabel="Lengte"
                    inputUnit="m"
                    onChangeCallback={(newValue) => {
                        props.setConfiguration({
                            ...props.configuration,
                            customDimensions: {
                                ...props.configuration.customDimensions,
                                lengthMeter: newValue
                            }
                        })
                    }}
                />
            </Grid>
            <Grid item xs={4}>
                <NumberInputWithSteppersSmallVariant
                    value={props.configuration.customDimensions.eaveHeightMeter}
                    minValue={2.5}
                    maxValue={6}
                    stepperButtonsStepAmount={1}
                    decimals={1}
                    inputLabel="Goothoogte"
                    inputUnit="m"
                    onChangeCallback={(newValue) => {
                        props.setConfiguration({
                            ...props.configuration,
                            customDimensions: {
                                ...props.configuration.customDimensions,
                                eaveHeightMeter: newValue
                            }
                        })
                    }}
                />
            </Grid>
            <Grid item xs={4}>
                <NumberInputWithSteppersSmallVariant
                    value={props.configuration.customDimensions.roofPitchDegrees}
                    minValue={10}
                    maxValue={50}
                    stepperButtonsStepAmount={1}
                    decimals={0}
                    inputLabel="Dak helling"
                    inputUnit="°"
                    hideSteppers
                    onChangeCallback={(newValue) => {
                        props.setConfiguration({
                            ...props.configuration,
                            customDimensions: {
                                ...props.configuration.customDimensions,
                                roofPitchDegrees: newValue
                            }
                        })
                    }}
                />
            </Grid>
            <Grid item xs={8} alignSelf="center">
                <Slider
                    valueLabelDisplay="auto"
                    min={10}
                    max={50}
                    step={1}
                    marks
                    value={props.configuration.customDimensions.roofPitchDegrees}
                    onChange={(event: any) => {
                        props.setConfiguration({
                            ...props.configuration,
                            customDimensions: {
                                ...props.configuration.customDimensions,
                                roofPitchDegrees: event.target.value
                            }
                        })
                    }}
                />
            </Grid>
        </Grid>
    </>
}
